<template>
  <div class="page-classroom-edit">
    <div class="my-page">
      <van-form @submit="save" style="background-color: transparent;">
        <div class="my-card">
          <van-field
            required
            v-model="viewData.Title"
            name="Title"
            label="成长册标题"
            maxlength="20"
            placeholder="成长册标题"
            :rules="[{ required: true, message: '请填写成长册标题' }]"
          />
          <van-field
            required
            v-model="viewData.Content"
            rows="4"
            label="成长册内容"
            type="textarea"
            placeholder="请输入成长册内容"
            :rules="[{ required: true, message: '请输入成长册内容' }]"
          />
          <van-field required name="IsOpen" label="是否公开">
            <template #input>
              <van-switch v-model="viewData.IsOpen" size="20" />
            </template>
          </van-field>
        </div>
        <div class="my-card">
          <van-field name="accessoryList" label="成长册照片">
            <template #input>
              <van-uploader
                v-model="viewData.accessoryList"
                :deletable="true"
                :preview-full-image="false"
                accept="image/*"
                :before-read="beforeRead"
                @click-preview="review"
              />
            </template>
          </van-field>
        </div>
        <van-button
          :loading="loading"
          block
          color="#ffe500"
          type="info"
          native-type="submit"
          >完成</van-button
        >
      </van-form>
    </div>
  </div>
</template>

<script>
import { Button, Form, Field, Uploader, Switch, Toast } from 'vant'
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Switch.name]: Switch,
    [Toast.name]: Toast
  },
  data () {
    return {
      id: null,
      viewData: {
        IsOpen: false,
        accessoryList: []
      },
      acceptUserList: [],
      loading: false
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.$axios
        .get('/api/Repair/Get', {
          ID: this.id
        })
        .then(res => {
          this.viewData = res.data
        })
    },
    onConfirm (value) {
      this.viewData.AcceptUserName = value.realName
      this.viewData.AcceptUserID = value.UserID
      this.showPicker = false
    },
    beforeRead (file) {
      Toast.loading({
        message: '加载中...',
        duration: 0
      })
      let formData = new FormData()
      formData.append('LastFile', file)
      formData.append('FileName', file.name)
      this.$axios.upload('/api/File/UploadFile', formData).then(res => {
        Toast.clear()
        if (res.code === 200) {
          this.viewData.accessoryList.push({
            name: file.name,
            osskey: res.data.osskey,
            uploadUrl: res.data.uploadUrl,
            url: res.data.url
          })
          return true
        } else {
          this.$toast.fail(res.msg || '操作失败')
          return false
        }
      })
    },
    review (file) {
      if (file.url) {
        window.open(file.url)
      }
    },
    selectFn (v) {
      let arr = []
      v.map(item => {
        arr.push(item.ClassName)
      })
      this.viewData.PeopleIdList = arr.join()
    },
    save () {
      let that = this
      const params = {
        ChildrenCircle: {
          ...this.viewData,
          AccessoryPostList: this.viewData.accessoryList
        },
        IsOpen: this.viewData.IsOpen
      }
      this.$axios.post('/api/ChildrenGrowthManual/Add', params).then(res => {
        if (res.code === 200) {
          this.$toast.success(res.msg || '操作成功')
          setTimeout(() => {
            that.$router.replace({
              path: 'GrowthBook'
            })
          }, 1000)
        } else {
          this.$toast.fail(res.msg || '操作失败')
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
